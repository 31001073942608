<template>
  <div id="pieoptions">
    <h4>Pie Left Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.offsetX"
        :max="300"
        :min="-300"
        show-input
      ></el-slider>
    </div>

    <h4>Pie Top Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.offsetY"
        :max="300"
        :min="-300"
        show-input
      ></el-slider>
    </div>

    <h4>DataLabels Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.pie.dataLabels.offset"
        :max="200"
        :min="-200"
        show-input
      ></el-slider>
    </div>

    <el-collapse>
      <el-collapse-item title="Padding">
        <h4>Top</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.top"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Right</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.right"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Bottom</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.bottom"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
        <h4>Left</h4>
        <div class="block">
          <el-slider
            @change="updateChart"
            v-model="grid.padding.left"
            :max="300"
            :min="-300"
            show-input
          ></el-slider>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        pie: {
          offsetX: 0,
          offsetY: 0,
          dataLabels: {
            offset: 0,
          },
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions;
      this.setNewOptions(p);
    },
  },
  props: {
    grid: {
      type: Object,
      required: true,
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        pie: {
          offsetX: p.pie.offsetX,
          offsetY: p.pie.offsetY,
          dataLabels: {
            offset: p.pie.dataLabels.offset,
          },
        },
      };
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        grid: {
          padding: {
            top: this.grid.padding.top,
            left: this.grid.padding.left,
            right: this.grid.padding.right,
            bottom: this.grid.padding.bottom,
          },
        },
        plotOptions: {
          pie: {
            offsetX: p.pie.offsetX,
            offsetY: p.pie.offsetY,
            dataLabels: {
              offset: p.pie.dataLabels.offset,
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
